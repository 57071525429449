<template>
  <div class="control has-icons-right">
    <textarea
      :id="id"
      class="input is-text"
      type="text"
      :placeholder="placeholder"
      :maxlength="maxLength"
      v-on:input="valueChanged"
      :required="isRequired"
      style="resize: vertical;min-height:40px"
    />
    <span class="icon is-right has-text-right" style="z-index:0">
      <p
        class="is-size-7 is-large mr-1 ml-1"
        :class="{ 'has-red-text': curLength == maxLength }"
      >
        {{ curLength }}/{{ maxLength }}
      </p>
    </span>
  </div>
</template>

<script>
export default {
  props: {
      id:{
          type:String,
          required: true
      },
    placeholder: {
      type: String,
      required: false,
    },
    maxLength: {
      type: Number,
      required: true,
    },
    isRequired:{
        type:Boolean,
        default: false
    }
  },
  data() {
    return {
      curLength: 0,
    };
  },
  methods: {
    valueChanged() {
      this.$emit("valChanged");

        //console.log(document.getElementById(this.id).value);
      this.curLength = document.getElementById(this.id).value.length;
    },
    setValue(v) {
      var obj = document.getElementById(this.id);
      obj.value = v;
      this.curLength = obj.value.length;
    },
    getValue() {
      return document.getElementById(this.id).value;
    }
  },
};
</script>