<template>
  <div class="mt-6">
    <div>
      <p class="panel-heading is-header">Collections</p>

      <div class="is-flex is-flex-direction-row is-justify-content-center">
        <div class="column is-two-thirds-desktop" style="overflow-y: auto" ref="scrollContent">
          <p
            class="is-flex is-flex-direction-row is-justify-content-center"
            v-if="loggedIn"
          >
            <a
              class="button is-primary is-small is-outlined is-text mb-3"
              style="text-decoration: none"
              @click="toggleNewForm"
            >
              Add New
            </a>
          </p>
          <div class="my-3" v-if="showNewForm">
            <AddCollectionForm @Hide="showNewForm = false" />
          </div>

          <!-- Letters first -->
          <div v-for="letter in letters" :key="letter">
            <p
              :ref="letter"
              v-if="HasAnyCollections(letter)"
              class="panel-block is-dark-blue has-text-white"
            >
              {{ letter }}
            </p>

            <!-- Then collections -->
            <div
              class=""
              v-for="collection in collections"
              :key="collection.id"
            >
              <div
                v-if="
                  CanShow(collection) &&
                  StartsWithLetter(letter, collection.title)
                "
              >
              <div class="panel-block">
                <!-- Title -->
                <a
                  class="is-link is-outlined is-text has-text-left mr-2"
                  @click="selectCollection(collection)"
                >
                  {{ collection.title }}
                </a>
                <!-- Status Indicators -->
                <figure v-if="loggedIn" class="image" style="width:15px">
                  <img
                    style="width: 100%"
                    :src="collection.featured ? require('@/assets/featured.png') : require('@/assets/not_featured.png')"
                    :title="'featured on table: '+collection.featured"
                  />
                </figure>
                <figure v-if="loggedIn" class="image" style="width:15px">
                  <img
                    style="width: 100%"
                    :src="collection.mobile_show ? require('@/assets/show_mobile.png') : require('@/assets/hide_mobile.png')"
                    :title="'mobile_show: '+collection.mobile_show"
                  />
                </figure>
                <figure v-if="loggedIn" class="image" style="width:15px">
                  <img
                    style="width: 100%"
                    :src="collection.table_show ? require('@/assets/show_table.png') : require('@/assets/hide_table.png')"
                    :title="'table_show: '+collection.table_show"
                  />
                </figure>

                <!-- Edit Button -->
                <a
                  class="button is-small is-rounded ml-2"
                  :class="{'is-primary': showEditForm && activeCollection.id == collection.id}"
                  @click="editCollection(collection)"
                  v-if="loggedIn"
                  >{{editBtnText(collection.id)}}</a
                >
              </div>
                <div
                  class="ml-3"
                  v-if="showEditForm && activeCollection.id === collection.id"
                >
                  <EditCollectionForm
                    ref="editCol"
                    :collection="activeCollection"
                    @hide="hideEdit"
                    @delete="showModal = true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="is-flex is-flex-direction-column is-justify-content-left mt-2 mr-2" style="max-width: 20px;">
        <div v-for="letter in letters" :key="letter" class="is-size-7-mobile">
          <a @click="GoToLetter(letter)">{{ letter }}</a>
        </div>
      </div>
      </div>

      
    </div>

    <b-modal
      :visible="showModal"
      heading="Deleting Collection"
      :subHeading="
        'Are you sure you want to delete this collection?\n' +
        activeCollection.title
      "
      confirmText="Delete"
      colorStyle="danger"
      @cancel="showModal = false"
      @confirm="removeCollection"
    />
  </div>
</template>


<script>
import AddCollectionForm from "./AddCollectionForm.vue";
import EditCollectionForm from "./EditCollectionForm.vue";
import BModal from "../../components/b-modal.vue";
export default {
  components: {
    AddCollectionForm,
    EditCollectionForm,
    BModal,
  },
  data() {
    return {
      collections: [],
      activeCollection: {},
      showEditForm: false,
      showNewForm: false,
      showModal: false,
      articles:["the","a","an"]
    };
  },
  async mounted() {
    try{
      this.$store.commit("AWAIT_RESPONSE", true);
      await this.$store.dispatch("collections/getAll");

      if(this.loggedIn) this.collections = this.$store.state.collections.all.items;
      else this.collections = this.$store.getters['collections/getMobileCollections'];
      
      this.SortCollections();
      this.$store.commit("AWAIT_RESPONSE", false);
    }
    catch(error){
      this.$store.dispatch("showErrorMessage", error);
    }
  },
  computed: {
    letters() {
      let letters = [];
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
        letters.push(String.fromCharCode([i]));
      }
      return letters;
    },
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    editBtnText(colId){
      if(this.showEditForm && this.activeCollection.id == colId) return "Close";
      else return "Edit";
    },
    SortCollections() {
      if(this.collections.length > 0) {
        this.collections.sort((a, b) => {
          var titleA = a.title;
          var titleB = b.title;
          if(this.StartsWithArticle(titleA)) titleA = titleA.substring(titleA.indexOf(" ") + 1);
          if(this.StartsWithArticle(titleB)) titleB = titleB.substring(titleB.indexOf(" ") + 1);
          (titleA > titleB ? 1 : -1)
        });
      }
    },
    selectCollection(col) {
      this.activeCollection = col;

      //redirect to specific collection page
      this.showEditForm = false;
      this.$store.commit("collections/setActiveCollection", col);
      this.$router.push("/collections/" + col.id);
    },
    editCollection(col) {
        if(this.activeCollection == col){
          this.showEditForm = !this.showEditForm;
        }
        else{
          this.activeCollection = col;
          this.showEditForm = true;
        }

        if(this.showEditForm) this.showNewForm = false;
    },
    async removeCollection() {
      try{
        this.showModal = false;

        this.$store.commit("AWAIT_RESPONSE", true);
        await this.$store.dispatch("collections/destroy", this.activeCollection);
        this.collections = await this.$store.dispatch("collections/getAll");
        this.$store.commit("AWAIT_RESPONSE", false);
      }
      catch(error){
        this.$store.dispatch("showErrorMessage", error);
      }
    },
    hideEdit: function () {
      this.showEditForm = false;
      this.$store.dispatch("collections/getAll");
    },
    toggleNewForm: function () {
      this.showNewForm = !this.showNewForm;

      if(this.showNewForm) this.showEditForm = false;
    },
    CanShow: function (collection) {
      if (this.loggedIn) return true;
      else return collection.mobile_show;
    },
    StartsWithLetter(letter, title) {
      if(this.StartsWithArticle(title)) title = title.substring(title.indexOf(" ") + 1);
      return title.charAt(0).toUpperCase() == letter;
    },
    HasAnyCollections(letter) {
      if(this.collections.length <= 0) return false;
      return this.collections.some((c) => {
        var title = c.title;
        if(this.StartsWithArticle(title)) title = title.substring(title.indexOf(" ") + 1);
        return title.charAt(0).toUpperCase() == letter;
      });
    },
    GoToLetter(letter){
      if(this.$refs[letter]){
        var y = this.$refs[letter].getBoundingClientRect().y;
        this.$refs.scrollContent.scrollTop = y;
      }
    },
    StartsWithArticle(string){
      return this.articles.some(x => x == string.split(" ")[0].toLowerCase())
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0px;
}
</style>