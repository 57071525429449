<template>
  <div class="modal" v-bind:class="{ 'is-active': visible }" style="z-index: 200">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box is-white">
        <h5 class="is-title is-size-4" :class="textStyle">{{ heading }}</h5>
         <p class="is-text is-size-6">{{ subHeading }}</p>
        <div class="block">
          <slot/>
        </div>
        <div class="buttons is-right">
          <button class="button is-light px-6 is-text" :class="{'is-static': buttonsStatic}" style="text-decoration: none" @click="onCancel">Cancel</button>
          <button class="button px-6 is-text" :class="[controlStyle, {'is-static': buttonsStatic || confirmStatic}]" style="text-decoration: none" @click="onConfirm">{{confirmText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: { type: String, default: "Header" },
    subHeading: {type: String, default: ""},
    colorStyle: { type: String, default: "info" },
    confirmText: {type: String, default: "Confirm"},
    visible: {type: Boolean, default: false},
    buttonsStatic: {type: Boolean, default: false},
    confirmStatic: {type: Boolean, default: false}
  },
  data() {
    return {
    };
  },
  computed: {
    textStyle() {
      return "has-text-" + this.colorStyle;
    },
    controlStyle() {
      return "is-" + this.colorStyle;
    },
  },
  methods: {
      onConfirm() {
        this.$emit("confirm");
      },
      onCancel() {
        this.$emit("cancel");
      },
  },
  setup() {},
};
</script>

<style scoped lang="scss">
</style>
