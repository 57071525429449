<template>
  <form @submit.prevent="submitForm">
    <!-- Form for editing a collection -->
    <div class="box">
      <h1 class="is-title is-size-4">Edit Collection</h1>
      <div class="field">
        <div class="label is-pulled-left is-text">Title</div>
        <div class="is-text has-red-text is-pulled-left ml-3">(Required)</div>
        <div class="control">
          <!-- <input
            ref="title"
            class="input is-text"
            type="text"
            placeholder="Title"
            v-on:input="titleChanged"
            maxlength="25"
            required
          /> -->
          <b-input
              ref="title"
              :id="'title'"
              :placeholder="'Collection title'"
              :maxLength="25"
              :isRequired="true"
              @valChanged="valueChanged"
            ></b-input>
        </div>
      </div>
      <div class="field">
        <div class="label is-pulled-left is-text">Description</div>
        <div class="control">
          <!-- <input
            ref="descrip"
            class="input is-text"
            type="text"
            placeholder="Description"
   
            maxlength="500"
          /> -->
          <b-text-area
              ref="descrip"
              :id="'descrip'"
              :placeholder="'Collection description'"
              :maxLength="500"
              ></b-text-area>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input
              ref="feat"
              type="checkbox"
     
            />
            Is Featured
          </label>
        </div>
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input
              ref="table"
              type="checkbox"
   
            />
            Show on table
          </label>
        </div>
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input
              ref="mobile"
              type="checkbox"

            />
            Show on mobile
          </label>
        </div>

        <div class="column">
          <div class="buttons">
            <button
              class="button is-primary is-pulled-right is-text"
              :class="{'disabled': !canSubmit}"
              style="text-decoration:none"
              
            >
              Save
            </button>
            <button
              class="button is-primary is-danger is-pulled-left is-text"
              v-on:click="deleteCollection"
              style="text-decoration:none"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import BInput from "../../components/b-input.vue";
import BTextArea from '../../components/b-textArea.vue';
export default {
  components: { BInput, BTextArea },
  props: ["collection"],
  data() {
    return {
      activeCollection: {},
      canSubmit: true
    };
  },
  mounted() {
    this.setValues();
  },
  watch: {
    collection: function () {
      this.setValues();
    },
  },
  methods: {
    setValues(){
      this.activeCollection = this.collection;
    this.$refs.title.setValue(this.collection.title);
      this.$refs.descrip.setValue(this.collection.description);
      this.$refs.feat.checked = this.collection.featured;
      this.$refs.table.checked = this.collection.table_show;
      this.$refs.mobile.checked = this.collection.mobile_show;
    },
    submitForm: function () {
      //console.log(this.activeCollection);
      try{
        this.activeCollection.title = this.$refs.title.getValue();
        this.activeCollection.description = this.$refs.descrip.getValue();
        this.activeCollection.featured = this.$refs.feat.checked;
        this.activeCollection.table_show =this.$refs.table.checked;
        this.activeCollection.mobile_show = this.$refs.mobile.checked;

        this.$store.dispatch("collections/update", this.activeCollection);
        this.$emit("hide");
      }
      catch(error){
        this.$store.dispatch("showErrorMessage", error);
      }
    },
    deleteCollection() {
      this.$emit("delete");
    },
    // removeCollection: function () {
    //   try{
    //     this.$store.dispatch("collections/destroy", this.activeCollection);

    //     this.$refs.title.setValue("");
    //     this.$refs.descrip.setValue("");
    //     this.$refs.feat.checked = false;
    //     this.$refs.table.checked = false;
    //     this.$refs.mobile.checked = false;

    //     this.$emit("hide");
    //   }
    //   catch(error){
    //     this.$store.dispatch("showErrorMessage", error);
    //   }
    // },
    valueChanged(){
      //console.log(this.$refs.title.value);
      this.canSubmit = this.$refs.title.getValue() != "";
    }
  },
  
};
</script>