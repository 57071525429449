<template>
  <form @submit.prevent="submitForm">
    <!-- Form for adding a new collection -->
    <div class="box">
      <h1 class="is-title is-size-4">Add New Collection</h1>
      <div class="field">
        
        <div class="is-text label is-pulled-left">Title</div>
        <div class="is-text has-red-text is-pulled-left ml-3">(Required)</div>
        <div class="control">
          <!-- <input
            ref="title"
            class="input is-text"
            type="text"
            placeholder="Title"
            v-model="collection.title"
            maxlength="25"
            required
          /> -->
          <b-input
              ref="title"
              :id="'title'"
              :placeholder="'Collection title'"
              :maxLength="25"
              :isRequired="true"
              @valChanged="valueChanged"
            ></b-input>
        </div>
      </div>
      <div class="field">
        <div class="is-text label is-pulled-left">Description</div>
        <div class="control">
          <!-- <input
            ref="descrip"
            class="input is-text"
            type="text"
            placeholder="Description"
            v-model="collection.description"
            maxlength="500"
          /> -->
          <b-text-area
              ref="descrip"
              :id="'descrip'"
              :placeholder="'Collection description'"
              :maxLength="500"
              ></b-text-area>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input ref="feat" type="checkbox" v-model="collection.featured" />
            Is Featured
          </label>
        </div>
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input
              ref="table"
              type="checkbox"
              v-model="collection.table_show"
            />
            Show on table
          </label>
        </div>
        <div class="column">
          <label class="checkbox is-pulled-left is-text">
            <input
              ref="mobile"
              type="checkbox"
              v-model="collection.mobile_show"
            />
            Show on mobile
          </label>
        </div>

        <div class="column">
          <div class="buttons">
            <button
              class="is-text button is-primary is-pulled-right"
              :class="{'disabled':!canSubmit}"
              style="text-decoration:none"
              
            >
              Add New
            </button>
            <button
              class="is-text button is-primary is-outlined is-pulled-right"
              v-on:click="this.$emit('Hide')"
              style="text-decoration:none"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import BInput from "../../components/b-input.vue";
import BTextArea from '../../components/b-textArea.vue';
export default {
  components: { BInput, BTextArea },
  data() {
    return {
      collection: {},
      canSubmit: false
    };
  },
  methods: {
    submitForm: function () {
      try{
        this.collection.title = this.$refs.title.getValue();
        this.collection.description = this.$refs.descrip.getValue();

        this.$store
          .dispatch("collections/create", this.collection)
          .then((res) => {
            //console.log("redirecting to " + res);
            this.$router.push("/collections/" + res);
          });
      }
      catch(error){
        this.$store.dispatch("showErrorMessage", error);
      }
    },
    valueChanged(){
      this.canSubmit = this.$refs.title.getValue() != null && this.$refs.title.getValue() != "";
    }
  }
};
</script>